import React from "react"

import Layout from "../components/layout/Layout"
import SEO from "../components/seo"

const IndexPage = () => {
  return(
    <Layout>
      <SEO title="Home" />
      <section className="section">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-6">
              <h1 className="title is-1 mt-6 is-hidden">CSS-Gems</h1>
              <h2 className="title is-4 mt-6">This site is a work in progress...</h2>
            </div>
            <div className="column is-6"></div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
